@font-face {
  font-family: "Black Han Sans";
  src: url("./BlackHanSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-ExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Pretendard";
  src: url("./Pretendard-Thin.otf") format("opentype");
  font-weight: 100;
}
